<template>
  <div class="fill-height container--fluid" style="width: 100%">
    <div class="fill-height container--fluid">
      <v-toolbar tile flat>
        <v-btn icon v-on:click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Ir atrás</v-toolbar-title>
        <v-spacer />

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              plain
              large
              @click="addToCart(profile)"
            >
              <v-icon color="green" size="44px">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar reservación</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              plain
              v-bind="attrs"
              v-on="on"
              icon
              @click="openDialog"
            >
              <v-icon size="24px" color="primary">mdi-bookmark-check</v-icon>
            </v-btn>
          </template>
          <span>Me Interesa</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              plain
              v-bind="attrs"
              v-on="on"
              icon
              @click="sendPreference('like')"
            >
              <v-icon size="24px" color="red">mdi-heart</v-icon>
            </v-btn>
          </template>
          <span>Me Gusta</span>
        </v-tooltip>
      </v-toolbar>
    </div>
    <v-container fluid class="fill-height">
      <v-row align="start" justify="start">
        <v-col cols="12" md="6">
          <v-container fluid class="mx-0">
            <v-row no-gutters>
              <v-col cols="12">
                <span
                  class="text-h6 text-md-h5 font-weight-ligh headline text-uppercase"
                >
                  {{ profile.name }}
                </span>
              </v-col>
              <v-col cols="12">
                <div class="boxdivider"></div>
              </v-col>
              <v-col cols="12">
                <v-carousel hide-delimiters cycle height="400" class="mt-5">
                  <v-carousel-item
                    v-for="item in profile.pictures"
                    :key="item.id"
                  >
                    <v-img
                      :src="item.url"
                      :lazy-src="require('@/assets/images/no-image.jpg')"
                      height="400"
                      max-height="400"
                      class="rounded-lg white--text justify-center elevation-1"
                    ></v-img>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col cols="12">
                <v-container fluid class="pa-0 mt-5">
                  <div :id="containerID" :style="containerCSS"></div>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
          <!--<v-row justify="start" align="start">
                        <v-card
                                class="d-flex fill-height fluid mt-5"
                                width="100%"
                                flat
                                elevation="0"
                                tile>
                            <v-img
                                    src="@/assets/images/mapa-location.png"
                                    transition="slide-x-transition"
                                    position="top"
                                    width="100%"
                                    height="400"
                                    max-height="400">
                                <v-toolbar class="ma-5" dense floating>
                                    <v-text-field
                                            hide-details
                                            prepend-icon="mdi-magnify"
                                            single-line>
                                    </v-text-field>
                                    <v-btn icon>
                                        <v-icon>mdi-crosshairs-gps</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </v-img>
                        </v-card>
                    </v-row>-->
        </v-col>
        <v-col cols="12" md="6">
          <v-card elevation="0" class="ml-1">
            <v-container fluid class="mx-0">
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-button font-weight-medium"
                    >Descripción:
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-format-quote-close</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        {{ profile.description }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium"
                    >Dirección:
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-hospital-marker</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        {{ profile.address }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">
                    Licencia Sanitaria:
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-certificate</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        {{ profile.license }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium"
                    >Teléfonos:
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-phone</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        <!--{{ profile.phone1 | formatPhone('MX') }}, {{ profile.phone2 || "NO" | formatPhone('US') }}-->
                        {{ profile.phone1 | formatPhone("MX") }},
                        {{ profile.phone2 | formatPhone("US") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">E-mail: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-email</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        {{ profile.email }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium"
                    >Horarios de Atención:
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-clock</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        {{ profile.servicehours }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium"
                    >Medios Sociales:</span
                  >
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            :href="profile.facebookprofile"
                            target="_blank"
                          >
                            <v-icon size="24px" color="primary"
                              >mdi-facebook</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Facebook</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            :href="profile.instagramprofile"
                            target="_blank"
                          >
                            <v-icon size="24px" color="primary"
                              >mdi-instagram</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Instagram</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            :href="profile.youtubeprofile"
                            target="_blank"
                          >
                            <v-icon size="24px" color="primary"
                              >mdi-youtube</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Youtube</span>
                      </v-tooltip>
                    </v-col>
                    <v-col>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            :href="profile.website"
                            target="_blank"
                          >
                            <v-icon size="24px" color="primary">mdi-web</v-icon>
                          </v-btn>
                        </template>
                        <span>Sitio Web</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <div
                    class="fill-height container--fluid justify-start mt-5"
                    v-if="profile.youtubeprofile != null"
                  >
                    <iframe
                      width="100%"
                      :height="$vuetify.breakpoint.xs ? 300 : 400"
                      :src="youtubeVideo"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="fill-height text-center mx-0">
      <v-row>
        <v-col cols="12">
          <v-sheet class="mx-0" max-width="100%">
            <v-img
              src="@/assets/images/join-bhc.jpg"
              class="grey lighten-2"
              width="100%"
              height="470"
            >
              <v-container fill-height bottom-gradient fluid class="ma-0">
                <v-row justify="center" align="center" class="mt-3 text-center">
                  <v-col>
                    <span
                      class="text-h6 text-md-h4 text-uppercase white--text font-weight-bold"
                      >Nuestras Mejores Clínicas</span
                    >
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-0">
                  <v-col cols="12">
                    <div class="fill-height">
                      <v-slide-group
                        center-active
                        v-model="model"
                        class="pa-0"
                        active-class="blue lighten-4"
                        next-icon="mdi-arrow-right-drop-circle-outline"
                        prev-icon="mdi-arrow-left-drop-circle-outline"
                        show-arrows
                      >
                        <v-container fluid class="container--fluid">
                          <v-row justify="center">
                            <v-slide-item
                              v-for="card in clinics"
                              :key="card.id"
                              v-slot="{ active, toggle }"
                            >
                              <!--width="250"-->
                              <v-card
                                v-if="card.pictures[0] != null"
                                :color="active ? undefined : 'white'"
                                class="ma-1 rounded-lg"
                                height="auto"
                                :width="$vuetify.breakpoint.mobile ? 300 : 300"
                                elevation="10"
                                @click="toggle"
                              >
                                <div v-on:click="getProfile(card.id)">
                                  <v-img
                                    v-if="card.pictures[0] != null"
                                    :src="card.pictures[0].url"
                                    :lazy-src="
                                      require('@/assets/images/no-image.jpg')
                                    "
                                    class="white--text align-end justify-start rounded-t-lg"
                                    position="center"
                                    height="200px"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                  >
                                  </v-img>
                                  <div class="row">
                                    <div class="col-12">
                                      <v-card-subtitle
                                        class="pb-0 text-subtitle-1 indigo--text text-capitalize text-truncate"
                                      >
                                        {{ card.name }}
                                      </v-card-subtitle>
                                    </div>
                                  </div>
                                  <v-card-actions class="mt-1 justify-center">
                                    <v-btn
                                      color="blue"
                                      icon
                                      :href="card.facebook_profile"
                                      target="_blank"
                                    >
                                      <v-icon size="24px" color="primary"
                                        >mdi-facebook</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      color="blue"
                                      icon
                                      :href="card.instagram_profile"
                                      target="_blank"
                                    >
                                      <v-icon size="24px" color="primary"
                                        >mdi-instagram</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      color="blue"
                                      icon
                                      :href="card.youtubeprofile"
                                      target="_blank"
                                    >
                                      <v-icon size="24px" color="primary"
                                        >mdi-youtube</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      color="blue"
                                      icon
                                      :href="card.web_page"
                                      target="_blank"
                                    >
                                      <v-icon size="24px" color="primary"
                                        >mdi-web</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      v-show="false"
                                      color="blue"
                                      icon
                                      target="_blank"
                                    >
                                      <v-icon size="24px" color="primary"
                                        >mdi-twitter</v-icon
                                      >
                                    </v-btn>
                                  </v-card-actions>
                                </div>
                              </v-card>
                            </v-slide-item>
                          </v-row>
                        </v-container>
                      </v-slide-group>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-sheet>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="350">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="row--dense mx-1 mt-2">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Correo electrónico"
                required
              >
              </v-text-field>
            </div>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDialog">
                Cancelar
              </v-btn>
              <v-btn
                :disabled="!valid"
                color="primary"
                text
                @click="acceptDialog"
              >
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-container>
    <footer-profile-component />
    <v-snackbar v-model="snackBar" :timeout="2000">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import clinicsService from "@/providers/ClinicsService";
import FooterProfileComponent from "@/layouts/app-layout/footer/FooterProfileComponent";
import preferencesService from "@/providers/PreferencesService";

export default {
  name: "CliniProfileComponent",
  components: { FooterProfileComponent },
  props: {
    clinic_id: Number,
    initialLocation: {
      type: Array,
      default: () => [-116.97480855890304, 32.547976944620714],
    },
    height: {
      type: String,
      default: "400px",
    },
    mapStyle: {
      type: String,
      default: "mapbox://styles/mapbox/streets-v11",
      // default: "mapbox://styles/mapbox/outdoors-v11",
    },
    color: {
      type: String,
      default: "orange",
    },
    containerID: {
      type: String,
      default: "map",
    },
  },
  data: () => ({
    id: 0,
    map: null,
    marker: null,
    model: null,
    snackBar: false,
    snackText: "",
    youtubeVideo: "",
    mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
    valid: true,
    dialog: false,
    email: null,
    clinics: [],
    location: [],
    profile: {
      id: 0,
      name: null,
      description: null,
      address: null,
      map_lng: null,
      map_lat: null,
      license: null,
      phones: null,
      email: null,
      website: null,
      facebookprofile: null,
      instagramprofile: null,
      youtubeprofile: null,
      servicehours: null,
      pictures: [],
      specialties: [],
      certifications: [],
      doctors: [],
      extraservices: [],
    },
    emailRules: [
      (v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
    ],
  }),
  computed: {
    containerCSS() {
      return {
        width: "100%",
        height: this.height,
      };
    },
  },
  filters: {
    formatPhone(phn, country) {
      if (phn == "undefined" || phn == null || phn === "") {
        return country;
      }
      switch (country) {
        case "US":
          return (
            "USA +1 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        case "MX":
          return (
            "MEX +52 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        default:
          return country;
      }
    },
  },
  methods: {
    //cart

    addToCart(profile) {
      const clinic = {
        category: "Clínica",
        name: profile.name,
        image: profile.pictures[0]?.url,
        profile: profile,
      };

      //   // Llama a la acción addToCart del store para agregar el clinic al carrito

      this.$store.dispatch("addToCart", clinic);

      //   console.log(`agregado clinic ${clinic.profile.name}`);
    },

    //////////////////////////////////////////

    goBack() {
      this.$router.push("/clinics");
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    loadURL() {
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const url = this.profile.youtubeprofile.split(
        /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      );
      // console.log("url", url);
      const YId =
        undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      // console.log("YId", YId);
      if (YId === url[0]) {
        console.log("not a youtube link");
      } else {
        console.log("it's  a youtube video");
      }
      const topOfQueue = youtubeEmbedTemplate.concat(YId);
      // console.log("topOfQueue", topOfQueue);
      this.youtubeVideo = topOfQueue;
    },
    getClinics() {
      clinicsService.getViewList().then((record) => {
        this.clinics = record.value;
        // console.log("Clinicas: ", this.clinics);
      });
    },
    getProfile(idClinic) {
      let record = null;
      this.map = null;
      clinicsService.getRecord(idClinic).then((fetch_data) => {
        record = fetch_data.value;
        // console.log('data:', record);
        this.profile.id = record.id;
        this.profile.name = record.name;
        this.profile.description = record.description;
        this.profile.address = record.address;
        this.profile.map_lng = record.map_lng;
        this.profile.map_lat = record.map_lat;
        this.profile.license = record.sanitary_license;
        this.profile.phone1 = record.phone1;
        this.profile.phone2 = record.phone2;
        this.profile.email = record.email;
        this.profile.website = record.web_page;
        this.profile.facebookprofile = record.facebook_profile;
        this.profile.instagramprofile = record.instagram_profile;
        this.profile.youtubeprofile = record.youtube_video;
        this.profile.servicehours = record.servicehours;
        this.profile.pictures = record.pictures;
        this.profile.doctors = record.doctors;
        this.profile.specialties = record.specialties;
        this.profile.certifications = record.certifications;
        // console.log('profile:', this.profile);

        // Gestion de la ubicacion en el mapa.
        const lng = this.profile.map_lng;
        const lat = this.profile.map_lat;
        // console.log('lng: ', lng);
        // console.log('lat: ', lat);
        this.setLocationCoordinates({ lng, lat });
        this.createMap();

        // Gestion del enlace del video en Youtube.
        if (this.profile.youtubeprofile != null) {
          this.loadURL();
        }
        // this.toTop();
      });
    },
    async createMap() {
      try {
        mapboxgl.accessToken = this.mapBoxApiKey;
        // Create map object
        this.map = new mapboxgl.Map({
          container: this.containerID,
          style: this.mapStyle,
          center: this.location,
          zoom: 10,
        });
        // Controles basicos de navegacion: zoom y rotacion.
        this.map.addControl(new mapboxgl.NavigationControl());

        // Evento del mapa.
        this.map.on("load", () => {
          // console.log('here');
          this.marker = new mapboxgl.Marker()
            .setLngLat(this.location)
            .addTo(this.map);
          // console.log(this.marker);
        });

        // Add Click Listener
        this.map.on("click", (event) => {
          console.log("evento: ", event);
          alert("Visitar el sitio oficial de MapBox");
          // window.location.href = 'https://www.mapbox.com/';
        });
      } catch (err) {
        console.error("map error", err);
      }
    },
    removeMapMarkers() {
      this.marker.remove();
      const oldMarker = document.querySelector(".mapboxgl-marker");
      if (oldMarker) {
        oldMarker.parentElement.removeChild(oldMarker);
      }
    },
    addMapMarker(lngLat) {
      console.log("lngLat: ", lngLat);
      this.marker = new mapboxgl.Marker()
        .setLngLat(this.location)
        .addTo(this.map);
      console.log(this.marker);
      /*new mapboxgl.Marker({
                    color: this.color})
                    .setLngLat(lngLat)
                    .addTo(this.map);*/
    },
    setLocationCoordinates(lngLat) {
      this.location = [lngLat.lng, lngLat.lat];
    },
    setLocation(lngLat) {
      this.removeMapMarkers();
      this.addMapMarker(lngLat);
      this.setLocationCoordinates(lngLat);
      // this.$emit("input", this.location);
    },
    requestLocation() {
      // Request to get the user's current location
      window.navigator.geolocation.getCurrentPosition((position) => {
        // get the latitude and longitude returned
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        // set location data
        this.setLocation({ lng, lat });
        this.map.flyTo({ center: [lng, lat], zoom: 15 });
      });
    },
    sendPreference(tag_type) {
      let tagView = tag_type === "mark" ? "Me interesa" : "Me Gusta";
      preferencesService.addRecord(this.email, tag_type, 3).then((result) => {
        if (result.success) {
          this.snackText =
            tag_type === "mark"
              ? `Se ha enviado los datos de esta vista etiquedada con ${tagView}! a su buzón de correo!`
              : `La vista ha sido etiquetada con ${tagView}!`;
          // Enviar los datos por correo electronico.
          clinicsService.sendEmail(this.profile.id, this.email).then((data) => {
            if (data.success) {
              console.log("Correo enviado: ", data);
            } else {
              console.log("Error enviando correo: ", data);
            }
          });
        } else {
          this.snackText = "Un error impidió etiquetar la vista!";
        }
        this.snackBar = true;
      });
    },
    openDialog() {
      this.email = "";
      this.dialog = true;
      //
    },
    closeDialog() {
      this.email = "";
      this.dialog = false;
    },
    acceptDialog() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.sendPreference("mark");
        this.dialog = false;
      }
    },
  },
  mounted() {
    // this.clinic_id = this.$route.params.id;
    this.getClinics();
    // this.createMap();
    // this.interval = setInterval(() => this.updateLocation(), 60000);
    this.getProfile(this.$route.params.id);
  },
};
</script>

<style scoped>
/*.mapboxgl-marker {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border:1px solid gray;
        background-color:yellow;
    }*/
.boxdivider {
  background-color: transparent;
  width: 100%;
  height: 15px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
</style>
